import axiosClient from "./axiosClient";
import { serviceBase } from "./serviceBase";

export const registerCompanyService = serviceBase({
  request: (data) => axiosClient.post("register", data),
});

export const signInTokenCompanyService = serviceBase({
  request: (data) =>
    axiosClient.get("currentUser", {
      headers: {
        Authorization: `Token ${data}`,
      },
    }),
});

export const signInWithEmailService = serviceBase({
  request: (data) => axiosClient.post("login", data),
});

export const updateCompanyInfoService = serviceBase({
  request: (data) =>
    axiosClient.patch("updateUser", data, {
      headers: {
        Authorization: `Token ${localStorage.getItem("jwt_access_token")}`,
      },
    }),
});

export const getOrdersHistoryService = serviceBase({
  request: (data) =>
    axiosClient.get("orders/find/orders-history", {
      headers: {
        Authorization: `Token ${data}`,
      },
    }),
});


export default registerCompanyService;

import { createActions, createReducer } from "reduxsauce";

const { Types, Creators } = createActions({
  setProductsSearchText: ['searchText'],
});

export const SearchTextActionCreators = Creators;

const initialState = {
  searchText: "",
};

const setProductsSearchText = (state = initialState, action) => {
	return {
		...state,
		searchText: action.searchText.searchText
	};
};

export default createReducer(initialState, {
  [Types.SET_PRODUCTS_SEARCH_TEXT]:setProductsSearchText
});

export const removeEmptyStringFieldsRecursive = obj => {
    Object.keys(obj).forEach(key => {
      if (obj[key] === '') {
        // eslint-disable-next-line no-param-reassign
        delete obj[key];
      } else if (obj[key] && typeof obj[key] === 'object') {
        removeEmptyStringFieldsRecursive(obj[key]);
      }
    });
  };
  
  export default removeEmptyStringFieldsRecursive;
import { createActions, createReducer } from 'reduxsauce';

const { Types, Creators } = createActions({
  saveCheckoutLink: ['payload'],
});

export const InitPointCreator = Creators;

const initialState = {
  initPoint: '',
};

const saveCheckoutLink = (state = initialState, action) => {
  // eslint-disable-next-line camelcase
  const { init_point } = action.payload.result;
  return {
    ...state,
    initPoint: init_point,
  };
};

export default createReducer(initialState, {
  [Types.SAVE_CHECKOUT_LINK]: saveCheckoutLink,
});

import { createActions, createReducer } from "reduxsauce";

const { Types, Creators } = createActions({
  requestApplyDiscount: ["payload"],
  successfulApplyDiscount: ["payload"],
  errorApplyDiscount: ["error"],
});

export const DiscountActionCreators = Creators;

const initialState = {
  loading: false,
  discountCode: {},
  error: "",
};

const requestApplyDiscount = (state = initialState, action) => {
  return {
    ...state,
    loading: true,
    discountCode: {},
  };
};

const successfulApplyDiscount = (state = initialState, action) => {
  
  const discountCode = action.payload.result.discountCodeSafe;
  return {
    ...state,
    loading: false,
    discountCode,
  };
};

const errorApplyDiscount = (state = initialState, action) => ({
  ...state,
  loading: false,
  error: action.error,
  discountCode: {},
});

export default createReducer(initialState, {
  [Types.REQUEST_APPLY_DISCOUNT]: requestApplyDiscount,
  [Types.SUCCESSFUL_APPLY_DISCOUNT]: successfulApplyDiscount,
  [Types.ERROR_APPLY_DISCOUNT]: errorApplyDiscount,
});

  
import axiosClient from './axiosClient';
import { serviceBase } from './serviceBase';

export const getProductsService = serviceBase({
	request: () => axiosClient.get('product')
});

export const getCategoriesService = serviceBase({
	request: () => axiosClient.get('categories')
});

export default getProductsService;
import {checkIfVariantsMissing} from '../utils/cart'
import {applyPromoService} from '../services/promo'
export const ADD_TO_CART = "ADD_TO_CART";
export const DECREASE_QUANTITY = "DECREASE_QUANTITY";
export const DELETE_FROM_CART = "DELETE_FROM_CART";
export const DELETE_ALL_FROM_CART = "DELETE_ALL_FROM_CART";
export const ADD_SMARTBOOK_TO_CART = "ADD_SMARTBOOK_TO_CART";
export const UPDATE_CART = "UPDATE_CART";
// add to cart
export const addToCart =  (item, addToast, quantityCount, selectedVariants, history, redirectToCheckout) => async (
  dispatch
) => {
  if (checkIfVariantsMissing(item, selectedVariants)) {
    addToast("Hay opciones obligatorias que no selecciono, por favor revise los campos", {
      appearance: "error",
      autoDismiss: true,
    });
  } else {
    if (addToast) {
      addToast("Añadido al carrito", {
        appearance: "success",
        autoDismiss: true,
      });
    }

    dispatch({
      type: ADD_TO_CART,
      payload: {
        ...item,
        quantity: quantityCount,
        selectedVariants: selectedVariants,
      },
    });
    const cart = JSON.parse(localStorage.getItem("redux_localstorage_simple_cartData"));
    const response = await applyPromoService(cart); 
    dispatch({
      type: UPDATE_CART,
      payload: {
        cartData: response.cartData,
      },
    });
  }
  if(redirectToCheckout){
    history.push("/checkout");
  }
};
// decrease from cart
export const decreaseQuantity = (item, addToast) => (dispatch) => {
  if (addToast) {
    addToast("Se elimino el producto del carrito", {
      appearance: "warning",
      autoDismiss: true,
    });
  }
  dispatch({ type: DECREASE_QUANTITY, payload: item });
};
// delete from cart
export const deleteFromCart = (item, addToast) => (dispatch) => {
  if (addToast) {
    addToast("Se elimino el producto del carrito", {
      appearance: "error",
      autoDismiss: true,
    });
  }
  dispatch({ type: DELETE_FROM_CART, payload: item });
};
// delete all from cart
export const deleteAllFromCart = (addToast) => (dispatch) => {
  if (addToast) {
    addToast("Se eliminarón todos los productos del carrito", {
      appearance: "error",
      autoDismiss: true,
    });
  }
  dispatch({ type: DELETE_ALL_FROM_CART });
};

// get stock of cart item
export const cartItemStock = (item, color, size) => {
  if (item.stock) {
    return item.stock;
  }
  if (item.variation) {
    return item.variation
      .filter((single) => single.color === color)[0]
      .size.filter((single) => single.name === size)[0].stock;
  }
  return 5000;
};

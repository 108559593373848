import { createActions, createReducer } from 'reduxsauce';

const { Types, Creators } = createActions({
  requestCheckoutLink: ['checkoutInfo', 'orderTotal'],
  succesfulCheckoutLink: ['payload'],
  errorCheckoutLink: ['error'],
  externalPayment: [],
});

export const PaymentActionCreators = Creators;

const initialState = {
  loading: false,
  checkoutInfo: {},
  error: '',
  orderTotal: '',
  discountCode: {}
};

const requestCheckoutLink = (state = initialState, action) => {
  console.log(action);
  return {
    ...state,
    loading: true,
    checkoutInfo: action.checkoutInfo.orderInfo,
    orderTotal: action.checkoutInfo.orderTotal,
    discountCode: action.checkoutInfo.discountCode
  };
};

const externalPayment = (state = initialState) => {
  return {
    ...state,
    loading: false,
  };
}

const succesfulCheckoutLink = (state = initialState, action) => ({
  ...state,
  loading: false,
});

const errorCheckoutLink = (state = initialState, action) => ({
  ...state,
  loading: false,
 
});


export default createReducer(initialState, {
  [Types.REQUEST_CHECKOUT_LINK]: requestCheckoutLink,
  [Types.ERROR_CHECKOUT_LINK]: errorCheckoutLink,
  [Types.SUCCESFUL_CHECKOUT_LINK]: succesfulCheckoutLink,
  [Types.EXTERNAL_PAYMENT]: externalPayment,
  
});
import { createActions, createReducer } from "reduxsauce";

const { Types, Creators } = createActions({
  requestSendUserData: ["payload"],
  successfulSendUserData: ["payload"],
  errorSendUserData: ["error"],
});

export const SubscriptionUserData = Creators;

const initialState = {
  loading: false,
  infoUser: {},
  error: "",
  openModal: true
};

const requestSendUserData = (state = initialState, action) => {
  return {
    ...state,
    loading: true,
    infoUser: {},
  };
};

const successfulSendUserData = (state = initialState, action) => {
    const { result } = action.payload;
  return {
    ...state,
    loading: false,
    infoUser: result,
    openModal: false
  };
};

const errorSendUserData = (state = initialState, action) => ({
  ...state,
  loading: false,
  error: action.error,
  infoUser: {},
  openModal: false
});

export default createReducer(initialState, {
  [Types.REQUEST_SEND_USER_DATA]: requestSendUserData,
  [Types.SUCCESSFUL_SEND_USER_DATA]: successfulSendUserData,
  [Types.ERROR_SEND_USER_DATA]: errorSendUserData,
});

  
import { ProductsActionCreators } from '../reducers/productReducer';
import { SearchTextActionCreators} from '../reducers/searchTextReducer'
import {
	getProductsService,
	getCategoriesService
} from '../services/products';
// import * as MessageActions from 'app/store/actions/fuse/message.actions';

export const getProducts = data => async dispatch => {
	dispatch(ProductsActionCreators.requestProducts());
	try {
		const response = await getProductsService();
		dispatch(
			ProductsActionCreators.successfulProducts({
				result: response
			})
		);
	} catch (e) {
		// dispatch(MessageActions.showMessage({ message: e.message, variant: 'error' }));
		dispatch(ProductsActionCreators.errorProducts(e));
	}
};

export const getCategories = () => async (dispatch) => {
	dispatch(ProductsActionCreators.requestCategories());
	try {
	  const response = await getCategoriesService();
	  dispatch(
		ProductsActionCreators.successfulCategories({
		  result: response,
		})
	  );
	} catch (e) {
	  // dispatch(MessageActions.showMessage({ message: e.message, variant: 'error' }));
	  dispatch(ProductsActionCreators.errorCategories(e));
	}
  };

export const setProductsSearchText = (value, history) => (dispatch) => {
	dispatch(SearchTextActionCreators.setProductsSearchText({ searchText: value }));
	if(history.location.pathname !== '/shop'){
	  history.push("/shop");
	}
  };
import { createActions, createReducer } from "reduxsauce";

const { Types, Creators } = createActions({
  requestBannerText: [],
  setBannerText: ["response"],
});

export const BannerTextActionCreators = Creators;

const initialState = {
  bannerText: "",
  loading: false,
};

const requestBannerText = (state = initialState, action) => {
  return {
    ...state,
    loading: true,
  };
};

const setBannerText = (state = initialState, action) => {
  const text = action.response.result.bannerText.text;
  return {
    ...state,
    bannerText: text,
    loading: false,
  };
};

export default createReducer(initialState, {
  [Types.REQUEST_BANNER_TEXT]: requestBannerText,
  [Types.SET_BANNER_TEXT]: setBannerText,
});
